
import { reactive } from "vue"
import TabsComponent from "../navigation/TabsComponent.vue"

export default {
  components: { TabsComponent },
  setup() {
    const tabItems = reactive([
      { label: "General", routeName: "settingsHome" },
      { label: "Processing Presets", routeName: "processingCollection" },
      { label: "CP Presets", routeName: "customProcessingsCollection" },
      { label: "Orientations", routeName: "orientationsCollection" },
      { label: "Croppings", routeName: "croppingCollection" },
      { label: "Annotations", routeName: "annotationsCollection" },
      { label: "Context Vision Input", routeName: "CVInputCollection" },
      { label: "Windowings", routeName: "windowingsCollection" },
      { label: "Custom Processings", routeName: "ricustomprocessing" },
      { label: "Lut options", routeName: "luts" },
      { label: "Luts", routeName: "riluts" },
    ])
    return {
      tabItems,
    }
  },
}
